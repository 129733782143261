$global-font-secondary-color: #444;
$code-color: #c7254e;
$code-color-dark: #98c379;
$code-background-color: #f7f7f7;
$code-background-color-dark: #3f3f3f3f;

// Color map of the code highlight
$code-highlight-color-map: (
    /* Error */ "err": $code-error-color,
    /* Keyword */ "k": #ba4cde,
    /* KeywordConstant */ "kc": #0f5fc1,
    /* KeywordDeclaration */ "kd": #ba4cde,
    /* KeywordNamespace */ "kn": #ba4cde,
    /* KeywordPseudo */ "kp": #ba4cde,
    /* KeywordReserved */ "kr": #ba4cde,
    /* KeywordType */ "kt": #ba4cde,
    /* Name */ "n": #333333,
    /* NameAttribute */ "na": #0f5fc1,
    /* NameBuiltin */ "nb": #0f5fc1,
    /* NameBuiltinPseudo */ "bp": $code-color,
    /* NameClass */ "nc": #8250df,
    /* NameConstant */ "no": #ba4cde,
    /* NameDecorator */ "nd": #8250df,
    /* NameEntity */ "ni": #0f5fc1,
    /* NameException */ "ne": #0f5fc1,
    /* NameFunction */ "nf": #0f5fc1,
    /* NameFunctionMagic */ "fm": #0f5fc1,
    /* NameLabel */ "nl": #0f5fc1,
    /* NameNamespace */ "nn": #8462c2,
    /* NameOther */ "nx": #0f5fc1,
    /* NameProperty */ "py": #0f5fc1,
    /* NameTag */ "nt": #116329,
    /* NameVariable */ "nv": #953800,
    /* NameVariableClass */ "vc": #0f5fc1,
    /* NameVariableGlobal */ "vg": #0f5fc1,
    /* NameVariableInstance */ "vi": #0f5fc1,
    /* NameVariableMagic */ "vm": #0f5fc1,
    /* Literal */ "l": #0f5fc1,
    /* LiteralDate */ "ld": #0a3069,
    /* LiteralString */ "s": #0a3069,
    /* LiteralStringAffix */ "sa": #0a3069,
    /* LiteralStringBacktick */ "sb": #0a3069,
    /* LiteralStringChar */ "sc": #0a3069,
    /* LiteralStringDelimiter */ "dl": #0a3069,
    /* LiteralStringDoc */ "sd": #0a3069,
    /* LiteralStringDouble */ "s2": #0a3069,
    /* LiteralStringEscape */ "se": #0a3069,
    /* LiteralStringHeredoc */ "sh": #0a3069,
    /* LiteralStringInterpol */ "si": #0a3069,
    /* LiteralStringOther */ "sx": #0a3069,
    /* LiteralStringRegex */ "sr": #0a3069,
    /* LiteralStringSingle */ "s1": #0a3069,
    /* LiteralStringSymbol */ "ss": #0a3069,
    /* LiteralNumber */ "m": #0f5fc1,
    /* LiteralNumberBin */ "mb": #0f5fc1,
    /* LiteralNumberFloat */ "mf": #0f5fc1,
    /* LiteralNumberHex */ "mh": #0f5fc1,
    /* LiteralNumberInteger */ "mi": #0f5fc1,
    /* LiteralNumberIntegerLong */ "il": #0f5fc1,
    /* LiteralNumberOct */ "mo": #0f5fc1,
    /* Operator */ "o": #ba4cde,
    /* OperatorWord */ "ow": #ba4cde,
    /* Punctuation */ "p": #333333,
    /* Comment */ "c": #6e7781,
    /* CommentHashbang */ "ch": #6e7781,
    /* CommentMultiline */ "cm": #6e7781,
    /* CommentSingle */ "c1": #6e7781,
    /* CommentSpecial */ "cs": #6e7781,
    /* CommentPreproc */ "cp": #0f5fc1,
    /* CommentPreprocFile */ "cpf": #6e7781,
    /* Generic */ "g": $code-color,
    /* GenericDeleted */ "gd": #82071e,
    /* GenericEmph */ "ge": $code-color,
    /* GenericError */ "gr": $code-error-color,
    /* GenericHeading */ "gh": $code-color,
    /* GenericInserted */ "gi": #116329,
    /* GenericOutput */ "go": $code-color,
    /* GenericPrompt */ "gp": $code-color,
    /* GenericStrong */ "gs": $code-color,
    /* GenericSubheading */ "gu": $code-color,
    /* GenericTraceback */ "gt": $code-error-color,
    /* GenericUnderline */ "gl": $code-color,
    /* TextWhitespace */ "w": $code-color,
    /* Other */ "x": $code-color
);
$code-highlight-color-map-dark: (
    /* Error */ "err": $code-error-color,
    /* Keyword */ "k": #ba4cde,
    /* KeywordConstant */ "kc": #d19a66,
    /* KeywordDeclaration */ "kd": #ba4cde,
    /* KeywordNamespace */ "kn": #ba4cde,
    /* KeywordPseudo */ "kp": #ba4cde,
    /* KeywordReserved */ "kr": #ba4cde,
    /* KeywordType */ "kt": #ba4cde,
    /* Name */ "n": #abb2bf,
    /* NameAttribute */ "na": #d19a66,
    /* NameBuiltin */ "nb": #4fafef,
    /* NameBuiltinPseudo */ "bp": #abb2bf,
    /* NameClass */ "nc": #4fafef,
    /* NameConstant */ "no": #d19a66,
    /* NameDecorator */ "nd": #4fafef,
    /* NameEntity */ "ni": #d19a66,
    /* NameException */ "ne": #d19a66,
    /* NameFunction */ "nf": #4fafef,
    /* NameFunctionMagic */ "fm": #d19a66,
    /* NameLabel */ "nl": #d19a66,
    /* NameNamespace */ "nn": #b45fde,
    /* NameOther */ "nx": #4fafef,
    /* NameProperty */ "py": #d19a66,
    /* NameTag */ "nt": #8ddb8c,
    /* NameVariable */ "nv": #f69d50,
    /* NameVariableClass */ "vc": #d19a66,
    /* NameVariableGlobal */ "vg": #d19a66,
    /* NameVariableInstance */ "vi": #d19a66,
    /* NameVariableMagic */ "vm": #d19a66,
    /* Literal */ "l": #d19a66,
    /* LiteralDate */ "ld": #70ca78,
    /* LiteralString */ "s": #70ca78,
    /* LiteralStringAffix */ "sa": #70ca78,
    /* LiteralStringBacktick */ "sb": #70ca78,
    /* LiteralStringChar */ "sc": #70ca78,
    /* LiteralStringDelimiter */ "dl": #70ca78,
    /* LiteralStringDoc */ "sd": #70ca78,
    /* LiteralStringDouble */ "s2": #70ca78,
    /* LiteralStringEscape */ "se": #70ca78,
    /* LiteralStringHeredoc */ "sh": #70ca78,
    /* LiteralStringInterpol */ "si": #70ca78,
    /* LiteralStringOther */ "sx": #70ca78,
    /* LiteralStringRegex */ "sr": #70ca78,
    /* LiteralStringSingle */ "s1": #70ca78,
    /* LiteralStringSymbol */ "ss": #70ca78,
    /* LiteralNumber */ "m": #d19a66,
    /* LiteralNumberBin */ "mb": #d19a66,
    /* LiteralNumberFloat */ "mf": #d19a66,
    /* LiteralNumberHex */ "mh": #d19a66,
    /* LiteralNumberInteger */ "mi": #d19a66,
    /* LiteralNumberIntegerLong */ "il": #d19a66,
    /* LiteralNumberOct */ "mo": #d19a66,
    /* Operator */ "o": #ba4cde,
    /* OperatorWord */ "ow": #ba4cde,
    /* Punctuation */ "p": #abb2bf,
    /* Comment */ "c": #768390,
    /* CommentHashbang */ "ch": #768390,
    /* CommentMultiline */ "cm": #768390,
    /* CommentSingle */ "c1": #768390,
    /* CommentSpecial */ "cs": #768390,
    /* CommentPreproc */ "cp": #d19a66,
    /* CommentPreprocFile */ "cpf": #768390,
    /* Generic */ "g": $code-color-dark,
    /* GenericDeleted */ "gd": #ffd8d3,
    /* GenericEmph */ "ge": $code-color-dark,
    /* GenericError */ "gr": $code-error-color,
    /* GenericHeading */ "gh": $code-color-dark,
    /* GenericInserted */ "gi": #b4f1b4,
    /* GenericOutput */ "go": $code-color-dark,
    /* GenericPrompt */ "gp": $code-color-dark,
    /* GenericStrong */ "gs": $code-color-dark,
    /* GenericSubheading */ "gu": $code-color-dark,
    /* GenericTraceback */ "gt": $code-error-color,
    /* GenericUnderline */ "gl": $code-color-dark,
    /* TextWhitespace */ "w": $code-color-dark,
    /* Other */ "x": $code-color-dark
);
